import React from 'react';
import {
    FormColumn,
    FormWrapper,
    FormSection,
    FormRow,
    FormButton,
    FormTitle,
    FormText,
    FormSpan,
} from './FormStyles';
import {Link} from "react-router-dom";

const Form = () => {
    return (
        <FormSection>
                <FormRow>
                    <FormColumn small>
                        <FormTitle>Информация</FormTitle>
                        <FormWrapper>
                            <FormText>
                                Уважаемые игроки! <br/>
                                Welcome pack. <br/>
                                100% + 100 FS <br/>
                                Promo codes: <br/>
                                - NONE <br/>
                                - NONE
                            </FormText>

                            <Link to='vavada'>
                                <FormButton>
                                    ВОЙТИ
                                </FormButton>
                            </Link>
                            <FormSpan>
                                 #vavada #vavada_casino #регистрация #online #зеркало #casino_vavada #рабочее_зеркало #онлайн #играть #казино_вавада #на_деньги #бесплатно #вавада #вавада_казино #официальный_вход
                                 #бонус  #войти #вход #слоты #бездепозитный #скачать  #официальный #промокод #бонус_за_регистрацию  #вход  #free #официальный_сайт #сайт #мобильная #игровые_автоматы #bonus #888 #2024 #free #бездеп
                            </FormSpan>
                        </FormWrapper>
                    </FormColumn>
                </FormRow>
        </FormSection>
    );
};

export default Form;
